console.log('Loaded...');

// Navbar Toggle
document.addEventListener('DOMContentLoaded', function () {
  // Get all "navbar-burger" elements
  let $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll('.navbar-burger'),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        // Get the "main-nav" element
        let $target = document.getElementById('main-nav');

        // Toggle the class on "main-nav"
        $target.classList.toggle('hidden');
      });
    });
  }

  let versionSwithcer = document.querySelector('[data-version-switcher]');
  if (versionSwithcer) {
    versionSwithcer.addEventListener('change', (e) => {
      location.href = '/calendars/' + e.target.value;
    });
  }
});
